<template>
  <ml-dialog
    width="1200px"
    ref="dialogRef"
    :title="title"
    :btnLoading="btnLoading"
    @click-submit="submitHandle"
    @click-close="closeHandle"
  >
    <template #body>
      <div class="search-box">
        <ml-form inline :model="searchData" ref="searchDataRef">
          <el-form-item label="阿米巴:">
            <el-select
              v-model="searchData.companyId"
              placeholder="请选择阿米巴"
              size="small"
              @change="searchFn"
              clearable
            >
              <el-option
                v-for="(item, index) in companyOptions"
                :key="index"
                :label="item.companyName"
                :value="item.companyId"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="用户名:">
            <el-input
              v-model="searchData.userNameLike"
              size="small"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-button size="small" type="primary" @click="searchFn">搜索</el-button>
          </el-form-item>
        </ml-form>
        <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
          <!-- 表格 -->

          <el-table
            :data="tableData.data"
            style="width: 100%"
            :height="450"
            highlight-current-row
            @current-change="handleSelectionChange"
          >
            <!-- <el-table-column type="selection" width="55" align="center" /> -->
            <el-table-column width="55" label="选择">
              <template #default="scope">
                <el-radio
                  v-model="selectUserId"
                  :label="scope.row.userId"
                  @change="handleChange(scope.row)"
                  :key="scope.row.userId"
                  >&nbsp;</el-radio
                >
              </template>
            </el-table-column>
            <el-table-column label="序号" type="index" width="55" align="center">
              <template #default="scope">
                {{ (tableData.page - 1) * tableData.size + (scope.$index + 1) }}
              </template>
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              align="center"
              label="账号"
              prop="userCode"
              min-width="10%"
            />
            <el-table-column
              show-overflow-tooltip
              align="center"
              label="用户名"
              prop="userName"
              min-width="10%"
            />
            <el-table-column
              show-overflow-tooltip
              align="center"
              label="阿米巴"
              prop="companyName"
              min-width="10%"
            />
            <el-table-column
              show-overflow-tooltip
              align="center"
              label="角色"
              prop="roleNames"
              min-width="12%"
            />
            <el-table-column
              show-overflow-tooltip
              align="center"
              label="角色类型"
              prop="userType"
              min-width="12%"
            >
              <template #default="scope">
                <span v-if="scope.row.userType === 'engineer'">工程师</span>
                <span v-if="scope.row.userType === 'division_manager'">事业部负责人</span>
                <span v-if="scope.row.userType === 'proprietor'">业主</span>
                <span v-if="scope.row.userType === 'amiba_manager'">阿米巴负责人</span>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <ml-pagination
            :total="tableData.total"
            :page="tableData.page"
            v-model="tableData.page"
            :size="tableData.size"
            @current-change="currentChange"
            @size-change="sizeChange"
          />
        </ml-tip>
      </div>
    </template>
  </ml-dialog>
</template>
  
  <script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { getTabberData, tabberHeight } from '@/utils'
import { ElMessage } from 'element-plus'
export default {
  name: 'distributeDialog',
  props: {
    conf: {
      type: Object,
      default() {
        return {}
      }
    },
    btnCode: {
      type: String,
      default: ''
    },
    btnName: {
      type: String,
      default: ''
    }
  },
  emits: ['success', 'close'],
  setup(props, context) {
    const { commit, dispatch, getters } = useStore()
    const dialogRef = ref()
    const dialogFormRef = ref()
    const title = ref(props.btnName)
    const selectUserId = ref('')
    const searchData = reactive({
      companyId: '',
      userCodeLike: '',
      userNameLike: '',
      state: 'Y'
    })
    let btnLoading = ref(false)

    let companyOptions = ref([])
    // 配置
    const tableData = reactive({
      data: [],
      total: 0,
      page: 1,
      size: 10
    })
    //分页事件
    watch([() => tableData.page, () => tableData.size], () => {
      getTableData()
    })
    const currentChange = page => {
      tableData.page = page
    }
    const sizeChange = size => {
      tableData.size = size
    }
    // 搜索
    const searchFn = () => {
      tableData.page === 1 ? getTableData() : (tableData.page = 1)
    }
    // 获取table列表数据
    const getTableData = async (
      params = { pageIndex: tableData.page, pageSize: tableData.size }
    ) => {
      const searchDataParams = searchData
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetUserList',
        params,
        searchDataParams
      )
      tableData.data = (data || []).map(d => {
        let roleNames = d.sysRoleVo.map(sys => sys.roleName).join(',')
        return {
          ...d,
          roleNames
        }
      })
      tableData.total = total || 0
    }

    let userSelect = ref([])
    const handleSelectionChange = value => {
      // console.log(7323, value)
      // selectUserId.value = value.userId
    }

    // 表单提交
    const dialogData = reactive({
      buttonCode: props.btnCode,
      procInsId: props.conf && props.conf.taskDto && props.conf.taskDto.processInstanceId,
      taskId: props.conf && props.conf.taskDto && props.conf.taskDto.id,
      variables: props.conf && props.conf.variables,
      comment: '',
      toUserId: '',
      customParamJson: '',
      fileList: [],
      nodeId: props.conf && props.conf.id,
      businessParamJson: '',
      created: props.conf && props.conf.taskDto && props.conf.taskDto.created,
      formConf: props.conf.formConf
    })
    const submitHandle = () => {
      if (!selectUserId.value) {
        ElMessage({
          message: '请选择一条数据',
          type: 'warning',
          plain: true
        })
        return
      }
      btnLoading.value = true
      dialogData.toUserId = selectUserId.value
      dispatch('fetchButtonClickRequest', dialogData).then(res => {
        btnLoading.value = false
        if (res && res.code === 200) {
          commit('setError', {
            status: true,
            title: res.message,
            message: '处理成功...',
            type: 'success'
          })
          context.emit('success')
        }
      })
    }
    const closeHandle = () => {
      context.emit('close')
    }
    const handleChange = row => {
      selectUserId.value = row.userId
    }
    onMounted(async () => {
      title.value = props.btnName
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetAllCompanyInfos', {})
      companyOptions.value = data || []
      getTableData()
    })
    return {
      dialogRef,
      dialogFormRef,
      title,
      searchData,
      submitHandle,
      closeHandle,
      currentChange,
      sizeChange,
      getTableData,
      btnLoading,
      searchFn,
      companyOptions,
      tableData,
      tabberHeight,
      dialogData,
      handleSelectionChange,
      userSelect,
      selectUserId,
      handleChange
    }
  }
}
</script>
<style lang="scss" scoped>
.search-box {
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
}
/deep/ .el-radio-group {
  display: block;
}
</style>