<template>
  <ml-dialog
    width="600px"
    ref="dialogRef"
    :title="title"
    :btnLoading="btnLoading"
    :showSubmitBtn="!formDisable"
    @click-submit="submitHandle"
    @click-close="closeHandle"
  >
    <template #body>
      <div class="form-box">
        <ml-form
          style="width: 100%"
          labelWidth="100px"
          :model="submitReportForm"
          ref="bindDialogFormRef"
          :rules="dialogFormRef"
          :disabled="formDisable"
        >
          <ml-date-picker
            prop="startTime"
            placeholder="请选择开始时间"
            label="开始时间:"
            type="datetime"
            :disable="formDisable"
            v-model="submitReportForm.startTime"
          />
          <ml-date-picker
            prop="endTime"
            placeholder="请选择结束时间"
            label="结束时间:"
            type="datetime"
            v-model="submitReportForm.endTime"
          />
          <ml-input
            prop="content"
            placeholder="请输入审批意见"
            label="审批意见"
            type="textarea"
            :rows="5"
            v-model="submitReportForm.content"
          />
          <el-form-item label="上传附件:">
            <ml-upload
              multiple
              autoUpload
              :showFileList="false"
              :action="action"
              :data="{ linkType: 'error' }"
              :headers="{ Authorization: toKen }"
              name="files"
              @on-success="onSuccess"
            >
              <el-button size="medium" icon="el-icon-circle-plus-outline" type="primary">
                上传附件
              </el-button>
            </ml-upload>
          </el-form-item>
          <el-form-item>
            <ml-list
              fileName="fileName"
              :showDelete="!formDisable"
              :showDown="formDisable"
              :list="submitReportForm.attachVos"
            />
          </el-form-item>
          <el-form-item label="备品备件消耗">
            <el-radio-group v-model="submitReportForm.partFlag">
              <el-radio label="N">无</el-radio>
              <el-radio label="Y">有</el-radio>
            </el-radio-group>
          </el-form-item>

          <ml-select
            v-if="submitReportForm.partFlag === 'Y'"
            prop="partId"
            placeholder="请选择备品备件"
            :options="partOptions"
            label="备品备件:"
            keyName="partId"
            labelName="partName"
            valueName="partId"
            v-model="submitReportForm.partId"
            @change="changeHandle"
          />

          <ml-input-number
            v-if="submitReportForm.partFlag === 'Y'"
            prop="partCount"
            placeholder="请输入数量"
            :min="0"
            :precision="0"
            label="数量"
            v-model="submitReportForm.partCount"
          />
        </ml-form>
      </div>
    </template>
  </ml-dialog>
</template>
  
  <script>
import { computed, nextTick, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { BASEURL, FILEUPLOADURL } from '@API'
import { getTabberData } from '@/utils'
export default {
  name: 'distributeDialog',
  props: {
    conf: {
      type: Object,
      default() {
        return {}
      }
    },
    btnCode: {
      type: String,
      default: ''
    },
    companyId: {
      type: String,
      default: ''
    },
    sourData: {
      type: Object,
      default() {
        return {}
      }
    },
    type: {
      type: String,
      default: 'edit'
    }
  },
  emits: ['success', 'close'],
  setup(props, context) {
    const { commit, dispatch, getters } = useStore()
    const toKen = computed(() => getters.getToken)
    const dialogRef = ref()
    const title = ref('提交报告')
    const maxStock = ref(props.type === 'view' ? Number(props.sourData.partCount) : 0)
    const formDisable = ref(props.type === 'view' ? true : false)
    watch(
      () => props.btnCode,
      () => {}
    )

    const dialogData = reactive({
      buttonCode: props.btnCode,
      procInsId: props.conf && props.conf.taskDto && props.conf.taskDto.processInstanceId,
      taskId: props.conf && props.conf.taskDto && props.conf.taskDto.id,
      variables: props.conf && props.conf.variables,
      comment: '',
      toUserId: '',
      customParamJson: '',
      fileList: [],
      nodeId: props.conf && props.conf.id,
      businessParamJson: '',
      created: props.conf && props.conf.taskDto && props.conf.taskDto.created,
      formConf: props.conf.formConf
    })
    let btnLoading = ref(false)

    // 上传附件
    const action = ref(`${BASEURL}${FILEUPLOADURL}`)
    const onSuccess = files => {
      if (files.data) {
        submitReportForm.attachVos = [...submitReportForm.attachVos, ...files.data]
      }
    }
    const bindDialogFormRef = ref()
    const partOptions = ref([])
    const submitReportForm = reactive({
      startTime: props.type === 'view' ? props.sourData.startTime : '',
      endTime: props.type === 'view' ? props.sourData.endTime : '',
      content: props.type === 'view' ? props.sourData.content : '',
      attachVos: props.type === 'view' ? props.sourData.attachVos : [],
      partFlag: props.type === 'view' ? props.sourData.partFlag : '',
      partId: props.type === 'view' ? props.sourData.partId : '',
      partCount: props.type === 'view' ? Number(props.sourData.partCount) : 0
    })
    // 校验
    const isPartFlagY = () => submitReportForm.partFlag === 'Y' && !!submitReportForm.partId
    const dialogFormRef = {
      startTime: [{ required: true, message: '请选择开始时间', trigger: 'blur' }],
      endTime: [{ required: true, message: '请选择结束时间', trigger: 'blur' }],
      content: [{ required: true, message: '请输入描述', trigger: 'blur' }],
      partFlag: [{ required: true, message: '请选择是否有备品备件', trigger: 'blur' }],
      partId: [{ validator: isPartFlagY, message: '请选择备品备件', trigger: 'blur' }]
    }

    const submitHandle = () => {
      bindDialogFormRef.value.CustomFormRef.validate().then(() => {
        if (submitReportForm.partFlag === 'Y' && !submitReportForm.partCount) {
          commit('setError', {
            status: true,
            title: '',
            message: '备品消耗数量不能为空',
            type: 'warning'
          })
          return
        }
        btnLoading.value = true
        dialogData.fileList = submitReportForm.attachVos
        dialogData.comment = submitReportForm.content
        dialogData.businessParamJson = JSON.stringify(submitReportForm)
        dispatch('fetchButtonClickRequest', dialogData)
          .then(res => {
            btnLoading.value = false
            if (res && res.code === 200) {
              commit('setError', {
                status: true,
                title: res.message,
                message: '处理成功...',
                type: 'success'
              })
              context.emit('success')
            }
          })
          .catch(() => {
            btnLoading.value = false
          })
      })
    }
    const closeHandle = () => {
      context.emit('close')
    }
    const changeHandle = () => {
      if (partOptions.value.length > 0) {
        const item = partOptions.value.find(d => d.partId === submitReportForm.partId)
        if (item) {
          maxStock.value = item.realStock - item.frozenStock
        } else {
          maxStock.value = 0
        }
        if (submitReportForm.partCount > maxStock.value) {
          submitReportForm.partCount = maxStock.value
        }
      }
    }
    onMounted(async () => {
      await nextTick()
      const sparePartsData = await getTabberData(dispatch, 'fetchGetspareParts', {
        companyId: props.companyId
      })
      partOptions.value = sparePartsData.data || []
    })
    return {
      dialogRef,
      dialogFormRef,
      title,
      dialogData,
      submitHandle,
      closeHandle,
      btnLoading,
      toKen,
      action,
      onSuccess,
      submitReportForm,
      bindDialogFormRef,
      partOptions,
      formDisable,
      maxStock,
      changeHandle
    }
  }
}
</script>

<style lang="scss" scoped>
.form-box {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
</style>