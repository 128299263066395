<template>
  <div class="process-schedule-new">
    <div class="process-schedule-new-left">
      <div class="process-schedule-new-left-header" :class="{ afterActiveBgColor: state }">
        <!--  
          <div class="process-schedule-left-time">
            <span v-if="item.createTime">{{ item.createTime }}</span>
            <span v-if="item.endTime"> - {{ item.endTime }}</span>
          </div> -->
      </div>
      <div class="process-schedule-new-left-box"></div>
    </div>
    <div class="process-schedule-new-right">
      <div class="process-schedule-new-left-time">
        <span v-if="item.createTime">{{ item.createTime }}</span>
        <span v-if="item.endTime"> - {{ item.endTime }}</span>
      </div>
      <div class="process-schedule-new-right-content-box">
        <div v-if="item.assigneeName" class="assigneeName">{{ item.assigneeName }}</div>
        <div v-if="item.activityName" class="activityName">{{ item.activityName }}</div>
        <div v-for="(comment, index) in item.commentList" :key="index" class="right-desc">
          处理描述：{{ comment.fullMessage }}
        </div>
        <div
          class="form-btn"
          v-if="
            item.processVariableInfo &&
            (item.processVariableInfo.businessParamJson || item.processVariableInfo.customParamJson)
          "
        >
          <span @click="viewFormHandle(item.processVariableInfo)">查看表单数据</span>
        </div>
        <div
          class="process-schedule-new-right-image"
          v-if="item.signName"
          :title="`${baseUrl}${filePreviewUrl}/${item.signName}`"
        >
          <el-image
            style="width: 50px; height: 50px"
            :src="`${baseUrl}${filePreviewUrl}/${item.signName}`"
            hide-on-click-modal
            :preview-src-list="[`${baseUrl}${filePreviewUrl}/${item.signName}`]"
          />
        </div>
        <div
          class="process-schedule-new-right-image"
          v-if="getFileList(item).files.length > 0 || getFileList(item).images.length"
        >
          <ml-list
            v-if="getFileList(item).files.length > 0"
            fileName="fileName"
            :showDelete="false"
            showDown
            :list="getFileList(item).files"
          />
          <el-image
            style="width: 50px; height: 50px"
            v-for="result in getFileList(item).images"
            :key="result"
            :src="result.url"
            hide-on-click-modal
            :preview-src-list="getFileList(item).images.map(v => v.url)"
          />
          <!-- <ml-upload
            disabled
            accept=".png, .jpeg, .jpg"
            list-type="picture-card"
            :fileLists="getFileList(item).images"
          >
          </ml-upload> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { BASEURL, FILEPREVIEWURL } from '@API'
export default {
  name: 'ProcessSchedule',
  props: {
    state: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tip: {
      type: String
    },
    signName: {
      type: String
    },
    accept: {
      type: String
    },
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  emits: ['view-form'],
  setup(props, context) {
    const baseUrl = ref(BASEURL)
    const filePreviewUrl = ref(FILEPREVIEWURL)
    // 区分图片与文件
    const getFileList = item => {
      const images = []
      const files = []

      if (item.fileList && item.fileList.length > 0) {
        item.fileList.map(result => {
          if (['jpg', 'jpeg', 'png'].includes(result.suffix.toLowerCase())) {
            images.push({
              name: result.fileName,
              url: `${BASEURL}${FILEPREVIEWURL}/${result.filePath}`
            })
          } else {
            files.push(result)
          }
        })
      }
      return { images, files }
    }
    const viewFormHandle = data => {
      context.emit('view-form', data)
    }
    return { getFileList, viewFormHandle, baseUrl, filePreviewUrl }
  }
}
</script>

<style lang="scss">
$imageWH: 60px;
.process-schedule-new {
  .afterActiveBgColor {
    &::after {
      content: '';
      background-color: $--color-success;
    }
  }
  display: flex;
  justify-content: center;
  &-left {
    // background-color: red;
    // height: 100%;
    width: 10px;
    // flex: 0 1;
    // min-height: 100px;
    display: flex;
    flex-direction: column;
    &-header {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      div {
        height: 25px;
        line-height: 25px;
        color: $--color-text-placeholder;
        &:first-child {
          color: $--color-text-regular;
          font-size: 16px;
          // font-weight: bold;
        }

        &.process-schedule-new-left-time {
          color: #666;
          font-size: 14px;
          font-weight: 400;
        }
      }
      &::after {
        content: '';
        width: 20px;
        height: 20px;
        background-color: $--color-text-placeholder;
        border-radius: 50%;
        position: absolute;
        right: -10px;
        top: 0;
      }
    }
    &-box {
      width: 100%;
      flex: 1;
      border-right: 1px solid $--border-color-light;
    }
  }
  &-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 40px 40px;
    > div {
      line-height: 25px;
      color: #333;
      &:first-child {
        color: $--color-text-regular;
        font-size: 16px;
        // font-weight: bold;
      }
    }
    &-image {
      padding: 5px 0;
      height: auto !important;
      .el-upload--picture-card {
        width: $imageWH;
        height: $imageWH;
        line-height: $imageWH;
        i {
          font-size: 20px;
        }
      }
      .el-upload-list--picture-card .el-upload-list__item {
        width: $imageWH;
        height: $imageWH;
        line-height: $imageWH;
      }
    }
  }

  .process-schedule-new-right-content-box {
    background: #f4faff;
    padding: 16px;
    border-radius: 4px;
    position: relative;
    min-height: 50px;
    margin-top: 10px;
  }
  .assigneeName {
    color: #333;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 8px;
  }
  .activityName {
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 0 16px;
    height: 32px;
    line-height: 32px;
    border-radius: 32px;
    color: #fff;
    background: #259dff;
  }
  .custom-list > div {
    margin: 0;
  }
  .form-btn {
    color: #259dff;
    cursor: pointer;
  }
}
.process-schedule-new:last-child .process-schedule-new-left-box {
  border: none;
}
.right-desc {
  word-break: break-all
}
</style>
