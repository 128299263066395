<template>
  <div class="fultServiceCenter-details">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="故障详情" />
    <ml-tip class="scroll-box" :showHeader="false" style="border: 0">
      <el-scrollbar :height="tabberHeight(185)">
        <div ref="pdfBoxRef" class="content-box">
          <!-- 表单 -->
          <ml-form
            ref="faultServiceCenterFormRef"
            labelWidth="200px"
            style="width: 1200px; margin: 20px auto 40px"
            :rules="faultServiceCenterFormRules"
            :model="faultServiceCenterForm"
          >
            <!-- 提示标题 -->
            <el-divider content-position="left" style="font-weight: bold">故障详情信息</el-divider>
            <div class="globalTitle fultServiceCenter-details-globalTitle">
              故障详情
              <el-image
                class="fultServiceCenter-details-globalTitle-image"
                v-if="errorOrderStatusImg"
                :src="errorOrderStatusImg"
              />
            </div>
            <el-row>
              <el-col :span="12">
                <ml-select
                  prop="companyId"
                  placeholder="请选择阿米巴"
                  :options="unitOptions"
                  label="阿米巴:"
                  keyName="companyId"
                  labelName="companyName"
                  valueName="companyId"
                  v-model="faultServiceCenterForm.companyId"
                  @change="setCompany"
                  disabled
                />
              </el-col>
              <el-col :span="12">
                <!-- 所属项目 -->
                <ml-select
                  prop="projectId"
                  placeholder="请选择所属项目"
                  :options="projectData"
                  label="所属项目:"
                  keyName="projectId"
                  labelName="projectName"
                  valueName="projectId"
                  v-model="faultServiceCenterForm.projectId"
                  disabled
                />
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <ml-select
                  prop="propertyType"
                  placeholder="请选择资产类别"
                  :options="propertyTypeList"
                  label="资产类别:"
                  keyName="label"
                  labelName="label"
                  valueName="label"
                  v-model="faultServiceCenterForm.propertyType"
                  @change="propertyTypeChange"
                  :disabled="formDisabled"
                />
              </el-col>
              <el-col :span="12">
                <ml-select
                  prop="deviceType"
                  placeholder="请选择设备类型"
                  :options="deviceTypeList"
                  label="设备类型:"
                  keyName="label"
                  labelName="label"
                  valueName="label"
                  v-model="faultServiceCenterForm.deviceType"
                  @change="deviceTypeChange"
                  :disabled="formDisabled"
                />
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <ml-select
                  prop="firstUnit"
                  placeholder="请输入所属一级单位"
                  :options="firstUnitList"
                  label="所属一级单位:"
                  keyName="label"
                  labelName="label"
                  valueName="label"
                  v-model="faultServiceCenterForm.firstUnit"
                  @change="searchfirstUnit"
                  :disabled="formDisabled"
                />
              </el-col>
              <el-col :span="12">
                <ml-select
                  prop="secondUnit"
                  placeholder="请输入所属二级单位"
                  :options="secondUnitList"
                  label="所属二级单位:"
                  keyName="label"
                  labelName="label"
                  valueName="label"
                  v-model="faultServiceCenterForm.secondUnit"
                  @change="getEquipmentManageata"
                  :disabled="formDisabled"
                />
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <!-- 设备 -->
                <ml-select
                  prop="deviceId"
                  placeholder="请选择设备"
                  :options="equipmentManageata"
                  label="设备:"
                  keyName="deviceId"
                  labelName="deviceName"
                  valueName="deviceId"
                  v-model="faultServiceCenterForm.deviceId"
                  :disabled="formDisabled"
                />
              </el-col>
              <el-col :span="12">
                <ml-input
                  prop="title"
                  label="标题:"
                  placeholder="请输入标题"
                  v-model.trim="faultServiceCenterForm.title"
                  :disabled="formDisabled"
                />
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <ml-select
                  prop="severity"
                  placeholder="请选择严重程度"
                  :options="severityData"
                  label="严重程度:"
                  labelName="label"
                  valueName="label"
                  v-model="faultServiceCenterForm.severity"
                  :disabled="formDisabled"
                />
              </el-col>
              <el-col :span="12">
                <!-- 流程 -->
                <ml-input
                  prop="processData.processName"
                  label="流程:"
                  placeholder="请选择流程"
                  v-model.trim="faultServiceCenterForm.processData.processName"
                  disabled
                />
              </el-col>
            </el-row>

            <ml-input
              prop="address"
              label="地点:"
              placeholder="请输入地点"
              v-model.trim="faultServiceCenterForm.address"
              :disabled="formDisabled"
            />

            <ml-input
              prop="errorDesc"
              label="故障描述:"
              :placeholder="formDisabled ? '' : '请输入故障描述'"
              type="textarea"
              :rows="5"
              v-model.trim="faultServiceCenterForm.errorDesc"
              :disabled="formDisabled"
            />
          </ml-form>

          <ml-form
            labelWidth="200px"
            style="width: 1200px; margin: 20px auto 40px"
            :model="faultServiceCenterForm"
          >
            <div class="globalTitle" v-if="faultServiceCenterForm.attachVos.length > 0">附件</div>
            <el-form-item label="附件:" v-if="faultServiceCenterForm.attachVos.length > 0">
              <ml-list
                fileName="fileName"
                :showDelete="!formDisabled"
                :showDown="true"
                :list="faultServiceCenterForm.attachVos"
              />
            </el-form-item>
            <ml-button
              :showCancel="false"
              submitText="上传附件"
              @click-submit="showUploadDia"
              v-if="!formDisabled"
            ></ml-button>
            <div class="globalTitle" v-if="faultServiceCenterForm.signName">签名</div>
            <el-form-item label="签名:" v-if="faultServiceCenterForm.signName">
              <el-image
                fit="scale-down"
                style="width: 100px; height: 100px"
                :src="`${BASEURL}${FILEPREVIEWURL}/${faultServiceCenterForm.signName}`"
                hide-on-click-modal
                :preview-src-list="[
                  `${BASEURL}${FILEPREVIEWURL}/${faultServiceCenterForm.signName}`
                ]"
              />
            </el-form-item>

            <div class="globalTitle" v-if="orderRepairRecordByError.partFlag === 'Y'">备品消耗</div>
            <ml-form
              v-if="orderRepairRecordByError.partFlag === 'Y'"
              labelWidth="200px"
              disabled
              style="width: 1200px; margin: 20px auto 40px"
              :model="orderRepairRecordByError"
            >
              <el-row>
                <el-col :span="12">
                  <ml-input
                    prop="partName"
                    placeholder="请输入消耗备品名称"
                    label="消耗备品名称:"
                    v-model="orderRepairRecordByError.partName"
                  />
                </el-col>
                <el-col :span="12">
                  <ml-input-number
                    prop="partCount"
                    placeholder="请输入数量"
                    label="数量:"
                    :min="0"
                    v-model="orderRepairRecordByError.partCount"
                  />
                </el-col>
              </el-row>
            </ml-form>

            <div class="globalTitle" v-if="deviceErrorLogsByError.length > 0">流程进度</div>
            <el-form-item v-if="deviceErrorLogsByError.length > 0" style="margin-bottom: 60px">
              <process-schedule-new
                disabled
                v-for="(item, index) in deviceErrorLogsByError"
                :key="item"
                :item="item"
                :state="index === 0"
                @view-form="viewFromHandle"
              />
            </el-form-item>
          </ml-form>
        </div>
      </el-scrollbar>
    </ml-tip>

    <!-- 处理故障按钮组 -->
    <div class="btn-box">
      <el-button
        v-for="item in customButtons"
        :key="item.value"
        size="medium"
        type="primary"
        @click="clickReportOrder(item)"
      >
        {{ item.name }}
      </el-button>
      <el-button size="medium" icon="el-icon-circle-close" @click="clickCancelFultServiceCenter">
        返回上一页
      </el-button>
      <el-button size="medium" icon="el-icon-download" type="primary" @click="clickDownloadExcel">
        导出
      </el-button>
    </div>
    <!-- 上传附件-资源管理器 -->
    <c-upload-dlg
      v-if="uploadConfig.length > 0"
      ref="cUploadDlgRef"
      v-model="uploadData"
      :config="uploadConfig"
    />
    <!-- 派单 -->
    <distribute-dialog
      v-if="showDistributeDialog"
      :conf="processCof"
      :btnCode="btnCode"
      :btnName="btnName"
      ref="distributeRef"
      @close="closeDistributedDialog"
      @success="successDistributedDialog"
    ></distribute-dialog>
    <!-- 办结 -->
    <completed-dialog
      v-if="showCompletedDialog"
      ref="completedRef"
      :conf="processCof"
      :btnCode="btnCode"
      :btnName="btnName"
      :type="completedType"
      :sourData="sourData"
      @close="closeCompletedDialog"
      @success="successCompletedDialog"
    ></completed-dialog>
    <!-- 提交报告 -->
    <submit-dialog
      v-if="showSubmitDialog"
      ref="submitRef"
      :conf="processCof"
      :btnCode="btnCode"
      :btnName="btnName"
      :type="submitType"
      :companyId="faultServiceCenterForm.companyId"
      :sourData="sourData"
      @close="closeSubmitDialog"
      @success="successSubmitDialog"
    ></submit-dialog>
  </div>
</template>

<script>
import { computed, nextTick, onMounted, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getTabberData, tabberHeight, formetData, searchParams, getPropertyTypeList } from '@/utils'
import ProcessScheduleNew from '@components/processScheduleNew.vue'
import { BASEURL, FILEPREVIEWURL } from '@API'
import html2canvas from 'html2canvas'
import jsPdf from 'jspdf'

import distributeDialog from '@/components/processDialog/distributeDialog'
import completedDialog from '@/components/processDialog/completedDialog'
import submitDialog from '@/components/processDialog/submitDialog'

// 待审核
import isAuditImg from '../../assets/image/is-audit.png'
// 待处理
import isDealImg from '../../assets/image/is-deal.png'
// 已办结
import completeImg from '../../assets/image/complete.png'
// 已完成
import completeIng from '../../assets/image/completeIng.png'
// 已处理
import deal from '../../assets/image/deal.png'
// 处理中
import dealIng from '../../assets/image/deal-ing.png'
// 未办结
import noComplete from '../../assets/image/no-complete.png'

export default {
  name: 'FultServiceCenterDetails',
  components: {
    ProcessScheduleNew,
    distributeDialog,
    completedDialog,
    submitDialog
  },
  setup() {
    const { commit, getters, dispatch } = useStore()
    const router = useRouter()
    const route = useRoute()
    const { errorId } = route.query
    const formDisabled = ref(true)
    // 待处理 （-1 已退回 0 已上报 ）处理中  1 已指派 2 待审核 已处理  3 已办结
    const errorStatus = ref('0')
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    const toKen = computed(() => getters.getToken)
    /* 开始 表单 **********************************/
    // 详情数据
    const faultServiceCenterFormRef = ref()
    // 表单配置
    // const faultServiceCenterFormParams = ref()
    const faultServiceCenterForm = reactive({
      address: '',
      attachVos: [],
      companyId: '',
      createById: '',
      createTime: '',
      deviceId: '',
      deviceInfo: {},
      deviceName: '',
      deviceType: '',
      errorDesc: '',
      errorId: '',
      errorStatus: '',
      evaluateStatus: '',
      eventTime: '',
      historyData: '',
      latitude: '',
      longitude: '',
      processData: {
        businessId: '',
        definitionKey: '',
        definitionId: '',
        procInsId: '',
        businessType: ''
      },
      projectId: '',
      propertyType: '',
      remark: '',
      reportType: '',
      reportUser: '',
      severity: '',
      signName: '',
      state: '',
      submitTime: '',
      title: '',
      updateTime: '',
      userType: '',
      errorType: '',
      province: '',
      firstUnit: '',
      secondUnit: '',
      city: '',
      area: '',
      isAuditPass: '',
      isAuditRefuse: '',
      isOrderBack: '',
      isOrderEvaluate: '',
      isRepeatSubmit: '',
      isReportBack: '',
      isReportComplete: '',
      isReportOrder: '',
      isReportSubmit: '',
      showStatusText: ''
    })
    // 表单校验
    const faultServiceCenterFormRules = {
      companyId: [{ required: true, message: '请选择阿米巴 ', trigger: 'blur' }],
      projectId: [{ required: true, message: '请选择所属项目', trigger: 'blur' }],
      propertyType: [{ required: true, message: '请输入资产类别', trigger: 'blur' }],
      deviceType: [{ required: true, message: '请输入设备类型', trigger: 'blur' }],
      deviceId: [{ required: true, message: '请选择设备', trigger: 'blur' }],
      title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
      severity: [{ required: true, message: '请选择严重程度', trigger: 'blur' }],
      address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
      processData: {
        definitionId: [{ required: true, message: '请选择流程', trigger: 'blur' }]
      }
    }

    // 资产类型
    const propertyTypeList = ref(getPropertyTypeList())
    // 设备类型
    const deviceTypeList = ref([])
    const propertyTypeChange = () => {
      faultServiceCenterForm.deviceType = ''
      faultServiceCenterForm.deviceId = ''
      faultServiceCenterForm.firstUnit = ''
      faultServiceCenterForm.secondUnit = ''
      secondUnitList.value = []
      fetchgetfirstUnitList()
      getEquipmentManageata()
    }
    // 监听资产类型变化
    watch(
      () => faultServiceCenterForm.propertyType,
      newvalue => {
        deviceTypeList.value = []
        if (newvalue) {
          const newPropertyTypeList = propertyTypeList.value.filter(item => item.label === newvalue)
          deviceTypeList.value = newPropertyTypeList[0].value
        }
      }
    )

    const searchfirstUnit = () => {
      faultServiceCenterForm.secondUnit = ''
      getEquipmentManageata()
    }

    const errorOrderStatusImg = ref()
    watch(
      () => faultServiceCenterForm.showStatusText,
      newvalue => {
        if (newvalue) {
          switch (newvalue) {
            case '待审核':
              errorOrderStatusImg.value = isAuditImg
              break
            case '已办结':
              errorOrderStatusImg.value = completeImg
              break
            case '已完成':
              errorOrderStatusImg.value = completeIng
              break
            case '已处理':
              errorOrderStatusImg.value = deal
              break
            case '处理中':
              errorOrderStatusImg.value = dealIng
              break
            case '未办结':
              errorOrderStatusImg.value = noComplete
              break

            default:
              errorOrderStatusImg.value = isDealImg
              break
          }
        }
      }
    )
    watch(
      () => faultServiceCenterForm.firstUnit,
      () => {
        fetchgetsecondUnitList()
      }
    )

    const deviceTypeChange = () => {
      faultServiceCenterForm.deviceId = ''
      faultServiceCenterForm.firstUnit = ''
      faultServiceCenterForm.secondUnit = ''
      fetchgetfirstUnitList()
      getEquipmentManageata()
    }
    // 一级单位
    const fetchgetfirstUnitList = () => {
      const { companyId, projectId, propertyType, deviceType } = faultServiceCenterForm
      const firstUnitParams = {
        projectId,
        companyId
      }
      if (propertyType) {
        firstUnitParams.propertyType = propertyType
      }
      if (deviceType) {
        firstUnitParams.deviceType = deviceType
      }
      dispatch('fetchgetfirstUnitList', firstUnitParams).then(data => {
        if (data && data.code === 200) {
          if (data.data && data.data.length > 0) {
            firstUnitList.value = data.data.map(item => {
              return {
                label: item
              }
            })
          } else {
            firstUnitList.value = []
          }
        }
      })
    }
    // 二级单位
    const fetchgetsecondUnitList = () => {
      const { companyId, projectId, propertyType, deviceType, firstUnit } = faultServiceCenterForm
      const secondUnitParams = {
        projectId,
        companyId,
        propertyType,
        deviceType,
        firstUnit
      }

      dispatch('fetchgetsecondUnitList', secondUnitParams).then(data => {
        if (data && data.code === 200) {
          if (data.data && data.data.length > 0) {
            secondUnitList.value = data.data.map(item => {
              return {
                label: item
              }
            })
          } else {
            secondUnitList.value = []
          }
        }
      })
    }

    // 备件消耗
    const orderRepairRecordByError = reactive({
      partFlag: '',
      partName: '',
      partCount: 0
    })

    // 故障流程进度
    const deviceErrorLogsByError = ref([])
    // 类型下拉列表
    const typeData = ref([{ label: '故障' }, { label: '缺陷' }])
    // 严重程度下拉列表
    const severityData = ref([
      { label: '轻微' },
      { label: '一般' },
      { label: '严重' },
      { label: '灾难' }
    ])
    // 单位名下拉列表
    const unitOptions = ref([])
    // 所属项目下拉列表
    const projectData = ref([])
    // 一级单位
    const firstUnitList = ref([])
    // 二级单位
    const secondUnitList = ref([])
    // 工单流程列表
    const processOption = ref([])
    // 流程列表
    const processData = ref([])

    // 设备下拉列表
    const equipmentManageata = ref([])
    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = {}
      if (isBusinessDepartment.value) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
    }
    const setCompany = () => {
      faultServiceCenterForm.projectId = ''
      faultServiceCenterForm.deviceId = ''
    }
    // 处理设备
    const getEquipmentManageata = async projectId => {
      const projectParams = {
        projectId,
        companyId: faultServiceCenterForm.companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetDeviceInfos', projectParams)
      equipmentManageata.value = data || []
    }
    watch(
      () => faultServiceCenterForm.projectId,
      newvalue => {
        getEquipmentManageata(newvalue)
        getProcessData()
      }
    )
    watch(
      () => faultServiceCenterForm.companyId,
      newvalue => {
        getProjectData(newvalue)
        fetchgetfirstUnitList()
      }
    )
    const getProcessData = () => {
      let params = {
        companyId: '',
        projectId: faultServiceCenterForm.projectId,
        businessType: 'device_error',
        pageIndex: 1,
        pageSize: 500
      }
      dispatch('fetchProjectProcessPage', params).then(res => {
        if (res.code === 200) {
          processData.value = res.data.records
        }
      })
    }
    // 获取单个故障信息
    const getFaultServiceCenterDetails = async () => {
      // 查看故障详情
      await dispatch('fetchDeviceErrorRecordById', errorId).then(data => {
        if (data && data.code === 200 && data.data) {
          errorStatus.value = data.data.errorStatus
          // 绑定阿米巴和项目ID
          const { firstUnit, secondUnit } = data.data.deviceInfo || {}

          Object.keys(faultServiceCenterForm).map(item => {
            if (['attachVos'].includes(item)) {
              faultServiceCenterForm[item] = data.data[item] || []
            } else if (['firstUnit'].includes(item)) {
              faultServiceCenterForm[item] = firstUnit
            } else if (['secondUnit'].includes(item)) {
              faultServiceCenterForm[item] = secondUnit
            } else {
              faultServiceCenterForm[item] = data.data[item]
            }
          })
        }
      })
    }

    const getDeviceErrorLogsByError = async () => {
      await dispatch('fetchDeviceErrorLogsByError', { errorId }).then(res => {
        if (res.code === 200) {
          let list = res.data || []
          deviceErrorLogsByError.value = list.map(d => {
            return {
              ...d,
              createTime: formetData(d.createTime),
              endTime: formetData(d.endTime)
            }
          })
        }
      })
    }

    const clickCancelFultServiceCenter = () => {
      router.go(-1)
    }
    // 导出 Excel
    const pdfBoxRef = ref()
    const clickDownloadExcel = () => {
      nextTick(() => {
        const w = pdfBoxRef.value.offsetWidth
        const h = pdfBoxRef.value.offsetHeight + 60
        html2canvas(pdfBoxRef.value, {
          useCORS: true,
          y: -100,
          x: (w - 1660) / 2,
          width: 1600,
          height: h
        }).then(canvas => {
          const contentW = canvas.width
          const contentH = canvas.height
          // 每页的高度
          const pageH = (contentW / 592.25) * 841.89
          // 款生成PDF的html高度
          let leftH = contentH
          // 页面偏移
          let position = 0
          // A4 尺寸
          const imgW = 595.28
          const imgH = (592.28 / contentW) * contentH
          //返回图片URL，参数：图片格式和清晰度(0-1)
          const pageData = canvas.toDataURL('image/png', 1.0)
          const pdf = new jsPdf('', 'pt', 'a4', true)

          // //方向默认竖直，尺寸ponits，格式a4【595.28,841.89]
          setTimeout(() => {
            // //需要dataUrl格式
            if (leftH < pageH) {
              pdf.addImage(pageData, 'png', 0, 0, imgW, imgH)
            } else {
              while (leftH > 0) {
                pdf.addImage(pageData, 'png', 0, position, imgW, imgH)
                leftH -= pageH
                position -= 841.89
                // 避免添加空白页面
                if (leftH > 0) {
                  pdf.addImage()
                }
              }
            }
            pdf.save(`${faultServiceCenterForm.title || '故障详情'}.pdf`)
          }, 600)
        })
      })
    }

    /** 流程处理 开始 2024-09-14 ***********************************/
    let btnCode = ref('')
    let btnName = ref('')
    let processCof = ref({})
    let customButtons = ref([])
    const getProcessConf = () => {
      dispatch('fetchGetTaskConfRequest', { errorId }).then(res => {
        if (res.code === 200) {
          let data = res.data
          processCof.value = data || {}
          customButtons.value = (data && data.customButtons) || []
          formDisabled.value = !customButtons.value.find(
            d => d.value === 'act_submit_type_repeatSubmit'
          )
        }
      })
    }

    const clickReportOrder = async item => {
      btnCode.value = item.value
      btnName.value = item.name
      // 按钮办结，通过，驳回
      if (
        item.value === 'act_submit_type_completed' ||
        item.value.indexOf('act_submit_type_approve') > -1 ||
        item.value === 'act_submit_type_reject'
      ) {
        completedType.value = 'edit'
        showCompletedDialog.value = true
        await nextTick()
        completedRef.value.dialogRef.showDialog = true
      } else if (
        // 按钮委派，转办，派单
        item.value === 'act_submit_type_delegate' ||
        item.value === 'act_submit_type_transfer' ||
        item.value === 'act_submit_type_distribute'
      ) {
        showDistributeDialog.value = true
        await nextTick()
        distributeRef.value.dialogRef.showDialog = true
      } else if (item.value === 'act_submit_type_submit') {
        submitType.value = 'edit'
        showSubmitDialog.value = true
        await nextTick()
        submitRef.value.dialogRef.showDialog = true
      } else if (item.value === 'act_submit_type_repeatSubmit') {
        // 重新提交
        clickSubmitFultServiceCenter()
      }
    }
    // 派单
    let showDistributeDialog = ref(false)
    const distributeRef = ref()

    const successDistributedDialog = () => {
      updateInfoHandle()
      closeDistributedDialog()
    }

    const closeDistributedDialog = () => {
      showDistributeDialog.value = false
      btnCode.value = ''
      btnName.value = ''
    }

    // 办结
    let showCompletedDialog = ref(false)
    const completedRef = ref()
    const completedType = ref('edit')
    const sourData = ref({})

    const successCompletedDialog = () => {
      updateInfoHandle()
      closeCompletedDialog()
    }

    const closeCompletedDialog = () => {
      showCompletedDialog.value = false
      btnCode.value = ''
      btnName.value = ''
    }

    // 提交报告
    let showSubmitDialog = ref(false)
    const submitRef = ref()
    const submitType = ref('edit')

    const successSubmitDialog = () => {
      updateInfoHandle()
      closeSubmitDialog()
    }

    const closeSubmitDialog = () => {
      showSubmitDialog.value = false
      submitType.value = 'edit'
      btnCode.value = ''
      btnName.value = ''
    }

    // 查看表单数据
    const viewFromHandle = async data => {
      btnCode.value = data.buttonCode
      btnName.value = '查看'
      if (data.businessParamJson) {
        submitType.value = 'view'
        sourData.value = JSON.parse(data.businessParamJson)
        showSubmitDialog.value = true
        await nextTick()
        submitRef.value.dialogRef.showDialog = true
      } else {
        completedType.value = 'view'
        showCompletedDialog.value = true
        let processVariableInfo = data
        processCof.value.formConf = JSON.parse(processVariableInfo.formConf)
        sourData.value = JSON.parse(processVariableInfo.customParamJson)
        await nextTick()
        completedRef.value.dialogRef.showDialog = true
      }
    }

    /** 流程处理 结束***********************************/

    /* 2021-12-15 新版上传文件管理器 wml ******************/
    // 上传资源管理器属性
    const cUploadDlgRef = ref()
    const uploadData = ref([])
    const uploadConfig = ref([])

    watch(
      () => uploadData.value,
      newvalue => {
        faultServiceCenterForm.attachVos = [...faultServiceCenterForm.attachVos, ...newvalue]
      }
    )
    // 打开对话框
    const showUploadDia = () => {
      const { companyId, projectId } = faultServiceCenterForm
      uploadData.value = []
      const nArr = []
      if (companyId) {
        nArr.push(companyId)
        if (projectId) {
          nArr.push(projectId)
        }
      }
      uploadConfig.value = [...nArr]
      nextTick(() => {
        cUploadDlgRef.value.uploadDialogRef.showDialog = true
      })
    }
    /* 2021-12-15 新版上传文件管理器 wml ******************/

    // 提交表单
    const clickSubmitFultServiceCenter = () => {
      faultServiceCenterFormRef.value.CustomFormRef.validate()
        .then(() => {
          const faultServiceCenterParams = searchParams(faultServiceCenterForm, [
            'firstUnit',
            'secondUnit'
          ])
          faultServiceCenterParams.deviceInfo = {}
          const { firstUnit, secondUnit } = faultServiceCenterForm
          if (firstUnit) {
            faultServiceCenterParams.deviceInfo.firstUnit = firstUnit
          }
          if (secondUnit) {
            faultServiceCenterParams.deviceInfo.secondUnit = secondUnit
          }
          dispatch('fetchNewAddDeviceErrorRecord', faultServiceCenterParams).then(data => {
            if (data && data.code === 200) {
              commit('setError', {
                status: true,
                title: data.message,
                message: '正在获取新的数据...',
                type: 'success'
              })
              updateInfoHandle()
            }
          })
        })
        .catch(() => {})
    }

    const updateInfoHandle = async () => {
      await getFaultServiceCenterDetails()
      await getProcessConf()
      await getDeviceErrorLogsByError()
    }

    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {
        noCompanyCode: 'business_department'
      })
      unitOptions.value = data || []
      // 获取单个故障信息
      updateInfoHandle()
    })
    return {
      pdfBoxRef,
      clickDownloadExcel,
      faultServiceCenterForm,
      unitOptions,
      projectData,
      clickCancelFultServiceCenter,
      tabberHeight,
      setCompany,
      equipmentManageata,
      typeData,
      severityData,
      toKen,
      errorStatus,
      orderRepairRecordByError,
      deviceErrorLogsByError,
      processOption,
      FILEPREVIEWURL,
      BASEURL,
      errorOrderStatusImg,
      processData,
      customButtons,
      showDistributeDialog,
      distributeRef,
      getDeviceErrorLogsByError,
      clickReportOrder,
      showCompletedDialog,
      completedRef,
      processCof,
      btnCode,
      btnName,
      closeCompletedDialog,
      successCompletedDialog,
      successDistributedDialog,
      closeDistributedDialog,
      showSubmitDialog,
      submitRef,
      successSubmitDialog,
      closeSubmitDialog,
      viewFromHandle,
      submitType,
      completedType,
      sourData,
      formDisabled,
      uploadData,
      showUploadDia,
      cUploadDlgRef,
      uploadConfig,
      clickSubmitFultServiceCenter,
      faultServiceCenterFormRef,
      faultServiceCenterFormRules,
      firstUnitList,
      searchfirstUnit,
      getEquipmentManageata,
      secondUnitList,
      propertyTypeList,
      deviceTypeList,
      propertyTypeChange,
      deviceTypeChange
    }
  }
}
</script>

<style lang="scss">
.fultServiceCenter-details {
  position: relative;
  &-globalTitle {
    position: relative;
    z-index: 1;
    &-image {
      position: absolute;
      width: 100px;
      top: -50px;
      left: 150px;
    }
  }
}

.name-tag-box {
  border: 1px solid $--border-color-base;
  border-radius: 4px;
  padding: 0 5px;
  min-height: 40px;
  max-height: 110px;
  overflow-x: hidden;
  .el-tag {
    margin-right: 5px;
  }
}
.assignedPeople-select {
  .el-input__inner {
    width: 150px;
  }
}

.assignedPeople-table-box {
  border-top: 1px solid $--border-color-lighter;
  padding-top: 10px;
}
.btn-box {
  display: flex;
  align-items: center;
  padding-left: 450px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 60px;
  background: #FFF;
  border-top: 1px dashed #ebeef5;
}
.content-box {
  padding-bottom: 60px;
  box-sizing: border-box;
}
</style>
